import React from 'react';
import RichTextInput from 'ra-input-rich-text';
import {
    List, Datagrid, TextField, DateField, NumberField,
    BooleanField, EditButton, ReferenceField, Filter, TextInput, ReferenceInput, AutocompleteInput
} from 'react-admin';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
    
import { Button } from '@material-ui/core';
const TestFilter = props => (
    <Filter variant="outlined" {...props}>

<TextInput variant="outlined"  label="Requisition No" source="requisitionNo~like" alwaysOn />
            <ReferenceInput label="Project" variant="outlined"  source="projectId" reference="work-role-projects" alwaysOn>
                <AutocompleteInput optionText={choice => { return (choice?.name) ? choice.name : "All"; } } />
            </ReferenceInput>
            <TextInput variant="outlined"  label="Requisition Subject" source="notiSubject~like" />
            <ReferenceInput label="Technical Skill" variant="outlined"  source="keySkillId" reference="key-skills" alwaysOn>
                <AutocompleteInput optionText={choice => { return (choice?.name) ? choice.name : "All"; } } />
            </ReferenceInput>
            <ReferenceInput label="Experience" variant="outlined"  source="experienceId" reference="experiences" >
                <AutocompleteInput optionText={choice => { return (choice?.name) ? choice.name : "All"; } } />
            </ReferenceInput>
            
    </Filter>
);
export const EvaluatorNotificationList = props => {
    const history = useHistory();
    let evaluatorId = 0;
    if (localStorage.evaluatorId) {
        evaluatorId = parseInt(localStorage.evaluatorId)
    }
    const RoleTypeId = localStorage.getItem("RoleTypeId"); 
    const isAdmin = (RoleTypeId?.toLocaleLowerCase() == "1" ) ? true : false;
    let propsObj = { ...props };
    if (propsObj.permissions) {
        let resourcename = `/${props.resource}`;
        let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
        if (myPermissions.length > 0) {
            let p = myPermissions[0];
            propsObj.hasList = (p.View == 1) ? true : false;
            propsObj.hasShow = (p.View == 1) ? true : false;
            propsObj.hasEdit = (p.Edit == 1) ? true : false;
            propsObj.hasDelete = false;//(p.Delete == 1) ? true : false;
            propsObj.hasCreate = false;//(p.Create == 1) ? true : false;
            propsObj.hasExport = (p.Export == 1) ? true : false;
        }
    }
    const CustomAction = (props) => {
        let { record } = props;
        
        return (
            <div style={{ display: "flex" }}>
              <Button  onClick={(e) => history.push(`/evaluatornotifications/${record.notificationId}`)} >   Edit</Button>
            </div>
          )
    
      }
      const CustomEmpty = () => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', margin: '20px' }}>
    <h2 style={{ color: '#555', fontWeight: '500', fontSize: '1.5rem' }}>No Records Found</h2>
</div>
)
    return (
        <List {...propsObj} hasCreate={false} hasDelete={false} title="Requisition Evaluation List" exporter={propsObj.hasExport}
            bulkActionButtons={false} filters={<TestFilter />}  
            empty={<CustomEmpty />} 
            filter={ !isAdmin?{ evaluatorId: evaluatorId }:null}
             >
            <Datagrid rowClick={  false}>
                {/* {propsObj.hasEdit && <EditButton />} */}
                {propsObj.hasEdit && 
                <CustomAction label="Action" source="id" /> }
                <ReferenceField  label="Requisition No" source="notificationId" reference="notifications" link={false}>
                    <TextField source="requisitionNo" />
            </ReferenceField>
                
            <ReferenceField  label="Date" source="notificationId" reference="notifications" link={false}>
                    <DateField source="date" /> 
            </ReferenceField>
                
                

            {/* <ReferenceField  label="For Project" source="projectId" reference="work-role-projects" link={false}>
                    <TextField source="name" />
            </ReferenceField> */}
            <ReferenceField  label="Project Start Date" source="notificationId" reference="notifications" link={false}>
                    <DateField source="startDate" /> 
            </ReferenceField>
            <ReferenceField  label="Project End Date" source="notificationId" reference="notifications" link={false}>
                    <DateField source="endDate" /> 
            </ReferenceField>
            <ReferenceField  label="Requisition Subject" source="notificationId" reference="notifications" link={false}>
                <TextField source="notiSubject"  />
            </ReferenceField>
            <ReferenceField  label="Total Resource Required" source="notificationId" reference="notifications" link={false}>
            <TextField source="totalResource" />
            </ReferenceField>
            <ReferenceField  label="Total Requisition Sent" source="notificationId" reference="notifications" link={false}>
            <TextField source="totalSent"  />
            </ReferenceField>
            <ReferenceField  label="Total Requisition Accepted" source="notificationId" reference="notifications" link={false}>
            <TextField source="totalAccepted" />
            </ReferenceField>
            <ReferenceField  label="Total Requisition Rejected" source="notificationId" reference="notifications" link={false}>
            <TextField source="totalRejected" />
            </ReferenceField>
             <ReferenceField  label="Total Requisition Pending" source="notificationId" reference="notifications" link={false}>
            <TextField source="totalPending" />  
            </ReferenceField>  
            
            
                       </Datagrid>
        </List>
    )
};