import React, { useCallback } from 'react';
import {    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Edit, SimpleForm, TextInput,SelectInput,
    DateInput, BooleanInput, NumberInput,ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useLocation
} from "react-router-dom";
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { CreateToolbar } from '../../CreateToolbar';
import queryString from 'query-string';


const useStyles = makeStyles(theme => ({
    subjectid:{
        display:'inline-block'
    }, 
    title: {
        display:'inline-block',
        marginLeft: '20px',
        width:'300px'
    }
}));

export const SubjectExpertsEdit = props => 
    
    
    {
        

     let propsObj = { ...props };
     let location = useLocation();
 
     const values = queryString.parse(location.search);
     let usertype = (values.type) ? values.type : 0;
 
     if (propsObj.permissions) {
         let resourcename = `/${props.resource}`;
         let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
         //let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() === "/candidates");
         propsObj.hasList = false;
         propsObj.hasShow = false;
         propsObj.hasEdit = false;
         propsObj.hasDelete = false;
         propsObj.hasCreate = false;
         propsObj.hasExport = false;
         if (myPermissions.length > 0) {
             let p = myPermissions[0];
             propsObj.hasList = (p.View == 1) ? true : false;
             propsObj.hasShow = (p.View == 1) ? true : false;
             propsObj.hasEdit = (p.Edit == 1) ? true : false;
             propsObj.hasDelete = (p.Delete == 1) ? true : false;
             propsObj.hasCreate = (p.Create == 1) ? true : false;
             propsObj.hasExport = (p.Export == 1) ? true : false;
         }
     }

    return (

        <Edit {...props} title="Subject Experts" redirect={false} successMessage="Subject Expert Updated" undoable={false}>
            <SimpleForm redirect="list"  toolbar={<CreateToolbar showdelete={propsObj.hasDelete} {...props}  />}  
            variant="outlined">
            <TextInput source="name" label="Name"/>
            <TextInput source="description" label="Description"/>
            </SimpleForm>
        </Edit>
    );
    }