import React, { useState, useEffect, useCallback } from 'react';
import {
    required, minLength, maxLength, minValue, maxValue, useRefresh,
    number, regex, email, choices,
    TabbedForm, FormTab, ReferenceInput,
    Edit, SimpleForm, TextInput, RichTextField, FileInput,FileField,
    SelectInput, EditButton, NumberField, DateField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField, FormDataConsumer,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField,AutocompleteInput
} from 'react-admin';
import { AddressFields } from "../../AddressFields";
import Button from '@material-ui/core/Button';
import { useStyles } from "../../formStyles";
import { makeStyles } from '@material-ui/core/styles';
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { Link } from '@material-ui/core';
import { CreateToolbar } from "../../CreateToolbar";
import { Drawer } from '@material-ui/core';
import { Route, useRouteMatch, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { CandidateEducationCreate } from "./CandidateEducationCreate";
import { CandidateEmploymentCreate } from "./CandidateEmploymentCreate";
import { CandidateLeaveCreate } from "./CandidateLeaveCreate";
import { CandidateSubjectExpertCreate } from "./CandidateSubjectExpertCreate";
import { CandidateSalaryCreate } from "./CandidateSalaryCreate";
import { CandidateWarningCreate } from "./CandidateWarningCreate";
import { CandidateReportingCreate } from "./CandidateReportingCreate";
import { CandidatePromotionCreate } from "./CandidatePromotionCreate";
import VSDeleteButton from "../../VSDeleteButton";
import { wrap } from 'module';
import { CandidateDepartmentCreate } from './CandidateDepartmentCreate';
import { CandidateKeySkillCreate } from './CandidateKeySkillCreate';
import EditIcon from '@material-ui/icons/Edit';
import { CandidateEducationEdit } from './CandidateEducationEdit';
import { CandidateEmploymentEdit } from './CandidateEmploymentEdit';
import { CandidateSalaryEdit } from './CandidateSalaryEdit';
import { CandidateKeySkilEdit } from './CandidateKeySkilEdit';
import { CandidateSubjectExpertEdit } from './CandidateSubjectExpertEdit';

const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: 750,
    },
}));
export const CandidateEdit = props => {


 
        

     let propsObj = { ...props };
     let location = useLocation();
 
     const values = queryString.parse(location.search);
     let usertype = (values.type) ? values.type : 0;
 
     if (propsObj.permissions) {
         let resourcename = `/${props.resource}`;
         let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
         //let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() === "/candidates");
         propsObj.hasList = false;
         propsObj.hasShow = false;
         propsObj.hasEdit = false;
         propsObj.hasDelete = false;
         propsObj.hasCreate = false;
         propsObj.hasExport = false;
         if (myPermissions.length > 0) {
             let p = myPermissions[0];
             propsObj.hasList = (p.View == 1) ? true : false;
             propsObj.hasShow = (p.View == 1) ? true : false;
             propsObj.hasEdit = (p.Edit == 1) ? true : false;
             propsObj.hasDelete = (p.Delete == 1) ? true : false;
             propsObj.hasCreate = (p.Create == 1) ? true : false;
             propsObj.hasExport = (p.Export == 1) ? true : false;
         }
     }
    const refresh = useRefresh();
    const classes = useStyles({});
    const myclasses = myStyles({});
    const [role, setRole] = useState("Candidate");
    const [uid, setUid] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isEmpEducation, setIsEmpEducation] = useState(false);
    const [isEmpEducationEdit, setIsEmpEducationEdit] = useState(false);
    const [isEmpEmployment, setIsEmpEmployment] = useState(false);
    const [isEmpEmploymentEdit, setIsEmpEmploymentEdit] = useState(false);
    const [isEmpLeave, setIsEmpLeave] = useState(false);
    const [isEmpSubjectExpert, setIsEmpSubjectExpert] = useState(false);
    const [isEmpSubjectExpertEdit, setIsEmpSubjectExpertEdit] = useState(false);

    const [isEmpDepartment, setIsEmpDepartment] = useState(false);
    const [isEmpSalary, setIsEmpSalary] = useState(false);
    const [isEmpSalaryEdit, setIsEmpSalaryEdit] = useState(false);
    
    const [isEmpWarning, setIsEmpWarning] = useState(false);
    const [isEmpReporting, setIsEmpReporting] = useState(false);
    const [isEmpPromotion, setIsEmpPromotion] = useState(false);
    const [isAddKeySkills, setIsAddKeySkills] = useState(false);
    const [isEditKeySkills, setIsEditKeySkills] = useState(false);
    const [IsEditRecord, setIsEditRecord] = useState(null);

    const match = useRouteMatch();
    


    let canseesalarytab = false;
    let issalarytab = localStorage.getItem("issalarytab");
    //console.log(issalarytab, "====issalarytab======");
    if (issalarytab == "true") {
        // console.log(canseesalarytab, "====condition======");
        canseesalarytab = true;
    }

    const url = match ? match.url : location.pathname;
    //console.log(match);
    //console.log(location);

    const onCloseForm = () => {
        setIsEmpEducation(false);
        setIsEmpEmployment(false);
        setIsEmpLeave(false);
        setIsEmpSubjectExpert(false);
        setIsEmpDepartment(false);
        setIsEmpSalary(false);
        setIsEmpWarning(false);
        setIsEmpReporting(false);
        setIsEmpPromotion(false);
        setIsAddKeySkills(false);
        setIsEmpEducationEdit(false);
        setIsEmpEmploymentEdit(false);
        setIsAddKeySkills(false);
        setIsEditKeySkills(false);
        setIsEmpSalaryEdit(false);
        setIsEmpSubjectExpertEdit(false);
        refresh();
    }
    const onCancel = () => {
        setIsEmpEducation(false);
        setIsEmpEmployment(false);
        setIsEmpLeave(false);
        setIsEmpSubjectExpert(false);
        setIsEmpDepartment(false);
        setIsEmpSalary(false);
        setIsEmpWarning(false);
        setIsEmpReporting(false);
        setIsEmpPromotion(false);
        setIsAddKeySkills(false);
        setIsEmpEducationEdit(false);
        setIsEmpEmploymentEdit(false);
        setIsAddKeySkills(false);
        setIsEmpSalaryEdit(false);
        setIsEditKeySkills(false);
        setIsEmpSubjectExpertEdit(false);
    }


    useEffect(() => {
        let uid = localStorage.getItem("userId");
        let type = localStorage.getItem("type");
        setRole(type);
        console.log(type);
        setUid(parseInt(uid));
        setIsAdmin(true);
        // if (type == "System")
        //     setIsAdmin(true);
    }, []);
    const CustomEdit = (props) => {
        let { record } = props;
        return <Button
            color="secondary" disableElevation
            size="medium"

            onClick={() => {
                props.onClick(record);
            }}
            startIcon={<EditIcon />}>{props.title}</Button>
    }
    return (
        <React.Fragment>
            <Edit {...props} title="Edit Candidate Tracker " undoable={false}>
                <TabbedForm variant="outlined" toolbar={(role == "Candidate") ? null : <CreateToolbar  showdelete={propsObj.hasDelete} {...props} mode={"Edit"} />}>
                    <FormTab value="DETAILS" label="Profile Overview">

                        <TextInput label="Candidate Code" source="candidateCode" validate={required()} fullWidth={true} formClassName={classes.first_inline_input} disabled />
                        <ReferenceInput label="Initials" validate={required()} formClassName={classes.last_inline_input}
                            source="initialsId"
                            reference="salutations"
                            fullWidth={true} sort={{ field: 'name', order: 'ASC' }} >
                            <SelectInput optionText="name" />
                        </ReferenceInput>

                        <TextInput source="firstName" label="First Name" validate={required()} fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput source="middleName" label="Middle Name" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput source="lastName" label="Last Name" validate={required()} fullWidth={true} formClassName={classes.last_three_input} />

                <TextInput source="personnelEmail" label="Personal Email" validate={[required()]} fullWidth={true} formClassName={classes.first_inline_input} />
                <TextInput source="alternateEmail" label="Alternate Email"   fullWidth={true} formClassName={classes.last_inline_input} />
                {/* <TextInput source="personnelEmail" label="Alternate Email 1" validate={[ email()]}  fullWidth={true} formClassName={classes.last_three_input} /> */}

                <TextInput source="mobile" label="Contact Number" validate={required()} fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput source="otherMobile" label="Alternate Contact No" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput source="homeMobile" label="Home Contact No" fullWidth={true} formClassName={classes.last_three_input} />

                <SelectInput validate={required()} source="gender" label="Gender" fullWidth={true} formClassName={classes.one_three_input}
                            choices={[
                                { id: 'male', name: 'Male' },
                                { id: 'female', name: 'Female' },
                                { id: 'other', name: 'Other' },
                            ]} />
                        <TextInput source="skypeId" label="Skype ID" fullWidth={true} formClassName={classes.two_three_input} />
                        <TextInput source="linkedInId"label="LinkedIn " fullWidth={true} formClassName={classes.last_three_input} />


                <TextInput source="emergencyContactName" label="Emergency Contact Name" fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput source="emergencyContactRelation" label="Emergency Contact Relation" fullWidth={true} formClassName={classes.last_three_input} />
                
                <TextInput source="emergencyMobile" label="Emergency Mobile No" fullWidth={true} formClassName={classes.last_three_input} />
                

                   

                        {/* <ReferenceInput validate={required()} label="User Role" source="roleTypeId" reference="role-types"
                            fullWidth={true} formClassName={classes.one_three_input}>
                            <SelectInput optionText="roleName" />
                        </ReferenceInput>
                        <ReferenceInput validate={required()} label="Leave Category" source="leaveCategoryId" reference="leave-categories"
                            fullWidth={true} formClassName={classes.two_three_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <ReferenceInput validate={required()} label="Shift" source="shiftId" reference="shifts"
                            fullWidth={true} formClassName={classes.last_three_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput> */}


                        <DateInput  label="Date Of Birth" source="dob" fullWidth={true} formClassName={classes.one_three_input} />
                        {/* <DateInput validate={required()} source="doj" fullWidth={true} formClassName={classes.two_three_input} />
                        <DateInput source="dol" fullWidth={true} formClassName={classes.last_three_input} /> */}


                        {/* <ReferenceInput  validate={required()} label="Candidate Role Type" source="empRoleTypeId" reference="role-types"
                            fullWidth={true} formClassName={classes.one_three_input}>
                            <SelectInput optionText="roleName" />
                        </ReferenceInput> */}
                        {/* <TextInput source="roEmail" fullWidth={true}    validate={[ email()]} formClassName={classes.two_three_input} /> */}
                        {/* 
                        <ReferenceInput label="Department" source="departmentId" reference="departments" disabled 
                            fullWidth={true} formClassName={classes.first_inline_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput> */}
                        <ReferenceInput perPage={500}  label="Role Applied For" source="designationId" reference="designations"
                            fullWidth={true} formClassName={classes.last_three_input} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}  
                                                >
                                                <AutocompleteInput
                                                        optionText={record =>
                                                            `${record?.name}`
                                                        }
                                                    />
                        </ReferenceInput>

                        {/* <ReferenceInput validate={required()} label="Vertical" source="workroleId" reference="work-roles"
                            fullWidth={true} formClassName={classes.first_inline_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <ReferenceInput validate={required()} label="Projects" source="workroleprojectId" reference="work-role-projects"
                            fullWidth={true} formClassName={classes.last_inline_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput> */}

                        <ReferenceInput validate={required()} label="Recruitment Source" source="recruitmentSourceId" reference="recruit-sources"
                            fullWidth={true} formClassName={classes.first_inline_input} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}  
                                                >
                                                <AutocompleteInput
                                                        optionText={record =>
                                                            `${record?.name}`
                                                        }
                                                    />
                        </ReferenceInput>
                        <ReferenceInput  label="Blood Group" source="bloodgroupId" reference="blood-groups"
                            fullWidth={true} formClassName={classes.last_inline_input}sort={{ field: 'name', order: 'ASC' }} >
                            <SelectInput optionText="name" />
                        </ReferenceInput>


                        <TextInput source="panCardNo" label="PAN No" fullWidth={true} formClassName={classes.first_inline_input} />
                        <TextInput source="adharCardNo" label="Aadhaar No" fullWidth={true} formClassName={classes.last_inline_input} />

                        <ReferenceInput validate={required()} label="Work Location" source="workLocationId" reference="work-locations"
                            fullWidth={true} formClassName={classes.first_inline_input}sort={{ field: 'name', order: 'ASC' }} >
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <ReferenceInput validate={required()} label="Week Off" source="weekOffId" reference="weekoffs"
                            fullWidth={true} formClassName={classes.last_inline_input}sort={{ field: 'name', order: 'ASC' }} >
                            <SelectInput optionText="name" />
                        </ReferenceInput>

                        {/* <TextInput disabled validate={required()} source="userName" fullWidth={true} formClassName={classes.first_inline_input} />
                        <TextInput validate={required()} source="password" fullWidth={true} formClassName={classes.last_inline_input} /> */}

                        <TextInput source="remarks" label="Remarks" fullWidth={true} multiline />

                        <ReferenceInput label="Status" source="status" reference="statuses" filter={{ statusGroup: 'ER' }}
                            fullWidth={true} formClassName={classes.first_inline_input}sort={{ field: 'status', order: 'ASC' }} >
                            <SelectInput optionText="status" />
                        </ReferenceInput>
                        
                        <DateInput disabled source="entryDate" label="Entry Date" fullWidth={true} formClassName={classes.last_inline_input} />
                        {/* <SelectInput validate={required()} source="empRoleTypeId" fullWidth={true}
                         formClassName={classes.last_inline_input}
                            choices={[
                                { id: 1, name: 'On-Roll' },
                                { id: 3, name: 'Freelancer' },
                                { id: 4, name: 'Contractual' },
                            ]} /> */}
                        <ReferenceInput perPage={500}  label="Subject" source="subjectId" reference="subjects"
                            fullWidth={true} formClassName={classes.first_inline_input} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}  
                                                >
                                                <AutocompleteInput
                                                        optionText={record =>
                                                            `${record?.name}`
                                                        }
                                                    />
                        </ReferenceInput>


                        <ReferenceInput label="Experience" source="experienceId" reference="experiences"
                    fullWidth={true} formClassName={classes.last_inline_input}sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}  
                                        >
                                        <AutocompleteInput
                                                optionText={record =>
                                                    `${record?.name}`
                                                }
                                            />
                    
                </ReferenceInput>
               

                
                                <ReferenceInput perPage={500} sort={{ field: 'name', order: 'ASC' }}variant="outlined" validate={[required()]} label="Recruiter" source="recruiterId"
                                filterToQuery={searchText => ({ 'name~like': searchText })} 
                                
                                 reference="recruiters" fullWidth={true} >
                                        <AutocompleteInput
                                            optionText={record =>
                                                `${record?.name}`
                                            }
                                        />
                                    </ReferenceInput>
                                
                
                
                
                    </FormTab>
                    <FormTab value="Other" label="Documents and Address">

                        {isAdmin && <FileInput source="resumeUrl1" formClassName={classes.first_inline_input}  
                            label="Candidate Resume" className="logourl">
                            <FileField source="resumeUrl1" title="Resume" />
                        </FileInput>
                        
                        }
                        <FileField source="resumeUrl" label="" title="Download Resume"   formClassName={classes.last_inline_input} />

                        {isAdmin && <FileInput source="coverLetterUrl1" formClassName={classes.first_inline_input}  
                            label="Cover Letter" className="logourl">
                            <FileField source="coverLetterUrl1" title="Resume"    />
                        </FileInput>
                        
                        }
                        <FileField source="coverLetterUrl" label="" title="Download Cover Letter"   formClassName={classes.last_inline_input} />


                        {/* {isAdmin && <FileInput source="coverLetterUrl" formClassName={classes.last_inline_input}
                            label="Cover Letter" className="logourl">
                            <FileField source="coverLetterUrl" title="Cover Letter" />
                        </FileInput>} */}

                        {isAdmin && <ImageInput source="photoUrl" formClassName={classes.first_inline_input}
                            label="Candidate Photo" accept="image/*" className="logourl">
                            <ImageField source="photoUrl" title="Icon" />
                        </ImageInput>}
                        <ImageField source="photoUrl" label="" formClassName={classes.last_inline_input} />

                        {/* {isAdmin && <ImageInput source="adharcardFrontUrl" formClassName={classes.one_4_input}
                        
                            label="Aadhar photo (Front)" accept="image/*" className="logourl">
                            <ImageField source="adharcardFrontUrl" title="Icon" />
                        </ImageInput>}
                        <ImageField source="adharcardFrontUrl" label="" formClassName={classes.two_4_input} /> */}

{isAdmin && <FileInput source="adharcardFrontUrl1" formClassName={classes.first_inline_input} 
                            label="Aadhaar Card" className="logourl">
                            <FileField source="adharcardFrontUrl1" title="Aadhaar Card" />
                        </FileInput>
                        
                        }
                        <FileField source="adharcardFrontUrl" label="" title="Download Aadhaar Card"   formClassName={classes.last_inline_input} />

                        {/* {isAdmin && <ImageInput source="adharcardBackUrl" formClassName={classes.three_4_input}
                            label="Aadhar photo (Back)" accept="image/*" className="logourl">
                            <ImageField source="adharcardBackUrl" title="Icon" />
                        </ImageInput>}
                        <ImageField source="adharcardBackUrl" label="" formClassName={classes.last_4_input} /> */}

                        {/* {isAdmin && <ImageInput source="pancardFrontUrl" formClassName={classes.one_4_input}
                            label="PanCard photo" accept="image/*" className="logourl">
                            <ImageField source="pancardFrontUrl" title="Icon" />
                        </ImageInput>}
                        <ImageField source="pancardFrontUrl" label="" formClassName={classes.two_4_input} />
 */}

                        {isAdmin && <FileInput source="pancardFrontUrl1" formClassName={classes.first_inline_input} 
                            label="PAN Card" className="logourl">
                            <FileField source="pancardFrontUrl1" title="PAN Card" />
                        </FileInput>
                        
                        }
                        <FileField source="pancardFrontUrl" label="" title="Download PAN Card"   formClassName={classes.last_inline_input} />

                        <div></div>
{/* 
                        {isAdmin && <ImageInput source="pancardBackUrl" formClassName={classes.three_4_input}
                            label="Pancard photo (Back)" accept="image/*" className="logourl">
                            <ImageField source="pancardBackUrl" title="Icon" />
                        </ImageInput>}
                        <ImageField source="pancardBackUrl" label="Pancard photo (Back)" formClassName={classes.last_4_input} /> */}

                        <h4>Present Address</h4>
                        <TextInput  label="Address Line 1" source="presentAddress1" fullWidth={true} formClassName={classes.one_three_input} />
                        <TextInput source="presentAddress2" label="Address Line 2" fullWidth={true} formClassName={classes.two_three_input} />
                        <TextInput label="PIN Code" source="presentPinCode" fullWidth={true} formClassName={classes.last_three_input} />

                        <FormDataConsumer>
                            {formDataProps => (
                                <AddressFields {...formDataProps}
                                    countryField="presentCountryId" stateField="presentStateId" cityField="presentCityId"
                                    countryLabel="Country" stateLabel="State" cityLabel="City"
                                    showAsAbove={true}
                                />
                            )}
                        </FormDataConsumer>


                        {/* <ReferenceInput  validate={required()} label="Present Country" source="presentCountryId" reference="countries"
                            fullWidth={true} formClassName={classes.last_three_input}>
                            <SelectInput optionText="countryName" />
                        </ReferenceInput>

                        <ReferenceInput  validate={required()} label="Present State" source="presentStateId" reference="states"
                            fullWidth={true} formClassName={classes.one_three_input}>
                            <SelectInput optionText="stateName" />
                        </ReferenceInput>
                        <ReferenceInput validate={required()}  label="Present City" source="presentCityId" reference="cities"
                            fullWidth={true} formClassName={classes.two_three_input}>
                            <SelectInput optionText="cityName" />
                        </ReferenceInput> */}

<h4>Permanent Address</h4>
                        <TextInput label="Address Line 1"  source="permanentAddress1" fullWidth={true} formClassName={classes.one_three_input} />
                        <TextInput label="Address Line 2" source="permanentAddress2" fullWidth={true} formClassName={classes.two_three_input} />
                        <TextInput label="PIN Code" source="permanentPinCode" fullWidth={true} formClassName={classes.last_three_input} />

                        <FormDataConsumer>
                            {formDataProps => (
                                <AddressFields {...formDataProps}
                                    countryField="permanentCountryId" stateField="permanentStateId" cityField="permanentCityId"
                                    countryLabel="Country" stateLabel="State" cityLabel="City"
                                />
                            )}
                        </FormDataConsumer>

                        <ReferenceInput label="Nationality" source="nationalityId" reference="nationalities"
                            fullWidth={true}>
                            <SelectInput optionText="name" initialValue={0} />
                        </ReferenceInput>
                        

                        {/* <ReferenceInput label="Permanent Country" source="permanentCountryId" reference="countries"
                            fullWidth={true} formClassName={classes.last_three_input}>
                            <SelectInput optionText="countryName" />
                        </ReferenceInput>

                        <ReferenceInput  validate={required()} label="Permanent State" source="permanentStateId" reference="states"
                            fullWidth={true} formClassName={classes.one_three_input}>
                            <SelectInput optionText="stateName" />
                        </ReferenceInput>
                        <ReferenceInput  validate={required()} label="Permanent City" source="permanentCityId" reference="cities"
                            fullWidth={true} formClassName={classes.two_three_input}>
                            <SelectInput optionText="cityName" />
                        </ReferenceInput> */}

                    </FormTab>
                    <FormTab value="EDUCATION" label="Education Background">
                        {isAdmin && <Button variant="contained" onClick={() => { setIsEmpEducation(true); }}
                            color="secondary">
                            Add Education
                        </Button>}
                        <ReferenceManyField perPage={500} reference="candidate-educations" target="candidateId" addLabel={false} fullWidth={true}>
                            <Datagrid rowClick={false} style={{ tableLayout: 'fixed' }}>
                                <VSDeleteButton {...props} />
                                <CustomEdit  title="Edit" onClick={(record) => {
                                    setIsEditRecord(record); setIsEmpEducationEdit(true);
                                }}  /> 
                                <ReferenceField source="educationId" reference="educations" link={false}><TextField source="name" /></ReferenceField>
                                <TextField source="institution" />
                                <ReferenceField perPage={500} source="subjectId" reference="subjects" link={false}><TextField source="name" /></ReferenceField>
                                <DateField source="startDate" />
                                <DateField source="endDate" />
                                
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>
                    <FormTab value="EMPLOYMENT" label="Work Experience">
                        {isAdmin && <Button variant="contained" onClick={() => { setIsEmpEmployment(true); }}
                            color="secondary">
                            Add Employment
                        </Button>}
                        <ReferenceManyField perPage={100} reference="candidate-prev-infos" target="candidateId" addLabel={false} fullWidth={true}>
                            <Datagrid rowClick={false} style={{ tableLayout: 'fixed' }}>
                                <VSDeleteButton {...props} />
                                <CustomEdit  title="Edit" onClick={(record) => {
                                    setIsEditRecord(record); setIsEmpEmploymentEdit(true);
                                }}  /> 
                                <TextField source="organization" />
                                <TextField source="organizationAddress" />
                                
                                <TextField source="reportingto" />
                                
                                <TextField source="department" />
                                <TextField source="designation" />
                                
                                <DateField source="doj" label="DOJ" />
                                <DateField source="dol" label="DOL"/>
                                {/* <TextField source="remarks" /> */}
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>

                    <FormTab value="SUBJECT" label="Subject">
                        {isAdmin && <Button variant="contained" onClick={() => { setIsEmpSubjectExpert(true); }}
                            color="secondary">
                            Add Subject Expert
                        </Button>}
                        <ReferenceManyField perPage={100} reference="candidate-subject-experts" target="candidateId" addLabel={false} fullWidth={true}>
                            <Datagrid rowClick={false} style={{ tableLayout: 'fixed' }}>
                                <VSDeleteButton {...props} />
                                <CustomEdit  title="Edit" onClick={(record) => {
                                    setIsEditRecord(record); setIsEmpSubjectExpertEdit(true);
                                }}  /> 
                                <DateField source="date" />
                                <ReferenceField perPage={500}  source="subjectId" reference="subjects" link={false}>
                                    <TextField source="name" />
                                    </ReferenceField>
                                    <ReferenceField source="proficiencyId" reference="statuses" link={false}>
                                    <TextField source="status" />
                                    </ReferenceField>
                             
                                <TextField source="remarks" />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>

                    {<FormTab value="SALARY" label="Banking Details">
                    <TextInput label="Account Name" source="bankAccountName" fullWidth={true} formClassName={classes.one_4_input} />
                    <TextInput label="Account Number" source="bankAccountNumber" fullWidth={true} formClassName={classes.two_4_input} /> 
                    <TextInput label="Bank Name" source="bankName" fullWidth={true} formClassName={classes.three_4_input} />
                    <TextInput label="IFSC Code" source="bankIFSCCode" fullWidth={true} formClassName={classes.last_4_input} />


                    <TextInput label="Bank Branch" source="bankBranch" fullWidth={true} formClassName={classes.one_4_input} />
                    <TextInput label="Bank Location" source="bankLocation" fullWidth={true} formClassName={classes.two_4_input} /> 
                    
                    

                        {isAdmin && <Button variant="contained" onClick={() => { setIsEmpSalary(true); }}
                            color="secondary">
                            Add Salary
                        </Button>}
                        <ReferenceManyField perPage={200} reference="candidate-salaries" sort={{ field: 'id', order: 'ASC' }}
                            target="candidatetId"
                            addLabel={false} fullWidth={true}>
                            <Datagrid rowClick={false} style={{ tableLayout: 'fixed' }}>
                                <VSDeleteButton {...props} />
                                <CustomEdit  title="Edit" onClick={(record) => {
                                    setIsEditRecord(record); setIsEmpSalaryEdit(true);
                                }}  /> 
                                <ReferenceField source="salaryHeadId" reference="salary-heads" link={false}><TextField source="name" /></ReferenceField>
                                
                                <NumberField source="grossSalary" />
                                <NumberField source="monthlySalary" />
                                <DateField source="effectiveFrom" />
                                <DateField source="effectiveTo" />
                                <TextField source="remarks" />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>}

                    <FormTab value="Other" label="Technical Skills">
                        <Button variant="contained" onClick={() => setIsAddKeySkills(true)}
                            color="secondary">
                            Add Technical Skills

                        </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="candidate-key-skills"
                            target="candidateId">

                            <Datagrid >

                                <VSDeleteButton {...props} />
                                <CustomEdit  title="Edit" onClick={(record) => {
                                    setIsEditRecord(record); setIsEditKeySkills(true);
                                }}  /> 


                                <ReferenceField source="keySkillId" reference="key-skills" label="Technical Skill" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <TextField source="remarks" />
                                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                    <TextField source="userName" />
                                </ReferenceField>

                                <DateField source="createdDate" label="Created Date" locales="fr-FR" />
                                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                    <TextField source="userName" />
                                </ReferenceField>
                                <DateField source="modifyDate" label="Updated Date" locales="fr-FR" />

                            </Datagrid>

                        </ReferenceManyField>
                    </FormTab>
                </TabbedForm>
            </Edit>
            <Drawer
                anchor="right"
                onClose={onCancel}
                classes={{
                    paper: myclasses.drawerPaper,
                }}
                open={isEmpEducation || isEmpEmployment || isEmpSalary || isEmpPromotion ||
                    isEmpLeave || isEmpSubjectExpert || isEmpWarning || isEmpReporting || isEmpDepartment
                    || isAddKeySkills || isEmpEducationEdit || isEmpEmploymentEdit || isEmpSalaryEdit || isEditKeySkills || isEmpSubjectExpertEdit
                    } >
                {isEmpEducation &&
                    <CandidateEducationCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isEmpEmployment &&
                    <CandidateEmploymentCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isEmpLeave &&
                    <CandidateLeaveCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isEmpSubjectExpert &&
                    <CandidateSubjectExpertCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isEmpSalary &&
                    <CandidateSalaryCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isEmpDepartment &&
                    <CandidateDepartmentCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isEmpPromotion &&
                    <CandidatePromotionCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isEmpWarning &&
                    <CandidateWarningCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isEmpReporting &&
                    <CandidateReportingCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isAddKeySkills &&
                    <CandidateKeySkillCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                    
                    {isEmpEducationEdit &&
                    <CandidateEducationEdit
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        record={IsEditRecord}
                        {...props} />}  
                    {isEmpEmploymentEdit &&
                    <CandidateEmploymentEdit    
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        record={IsEditRecord}
                        {...props} />}
                    {isEmpSalaryEdit &&
                    <CandidateSalaryEdit    
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        record={IsEditRecord}
                        {...props} />}

                    {isEditKeySkills &&
                    <CandidateKeySkilEdit    
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        record={IsEditRecord}
                        {...props} />}


{isEmpSubjectExpertEdit &&
                    <CandidateSubjectExpertEdit    
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        record={IsEditRecord}
                        {...props} />}






            </Drawer>
        </React.Fragment>
    );
}