import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useLocation
} from "react-router-dom";
import {
    List, Datagrid, TextField, DateField, SelectField,
    ReferenceField, ImageField, EmailField, Filter, TextInput, ReferenceInput, AutocompleteInput,
    NumberField, BooleanField, EditButton, SelectInput,SearchInput
} from 'react-admin';

import queryString from 'query-string';
import { ListActions } from "./ListActions";

const CandidateFilter = props => (
    <Filter {...props} variant="outlined">
        <TextInput variant="outlined" label="Candidate Code" source="candidateCode~like" alwaysOn />
        <TextInput variant="outlined" label="First Name" source="firstName~like" alwaysOn />
        {/* <TextInput variant="outlined" label="Lastname" source="lastName~like" alwaysOn /> */}
        <TextInput variant="outlined" label="Email" source="personnelEmail~like" alwaysOn />
        <TextInput variant="outlined" label="Mobile" source="mobile~like" alwaysOn />
        <ReferenceInput
            source="subjectId"
            perPage={2500}
            reference="subjects"
            sort={{ field: 'name', order: 'ASC' }}
        >
            <SelectInput source="name" />
        </ReferenceInput>
        
                        
                            <ReferenceInput label="Status" source="status" reference="statuses" filter={{ statusGroup: 'ER' }}
            fullWidth={true} sort={{ field: 'name', order: 'ASC' }}  >
            <SelectInput optionText="status" />
        </ReferenceInput>

        
        <ReferenceInput label="Status" source="status" reference="statuses" filter={{ statusGroup: 'ER' }}
            fullWidth={true} sort={{ field: 'name', order: 'ASC' }}  >
            <SelectInput optionText="status" />
        </ReferenceInput>

        <ReferenceInput perPage={500} label="Role Applied For" source="designationId" reference="designations"
            sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput label="Work Location" source="workLocationId" reference="work-locations"
            fullWidth={true} sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Week Off" source="weekOffId" reference="weekoffs"
            fullWidth={true} sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Experience" source="experienceId" reference="experiences"
            fullWidth={true} sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput label="Recruitment Source" source="recruitmentSourceId" reference="recruit-sources"
            fullWidth={true} sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Blood Group" source="bloodgroupId" reference="blood-groups"
            fullWidth={true} sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText="name" />
        </ReferenceInput>


    </Filter>
);

const FullNameField = (props) => {
    let { record } = props;
    return record ? (
        <span>
            {record.firstName} {record.middleName} {record.lastName}
        </span>
    ) : null;
};

export const CandidateList = props => {
    let propsObj = { ...props };
    let location = useLocation();

    const values = queryString.parse(location.search);
    let usertype = (values.type) ? values.type : 0;

    if (propsObj.permissions) {
        let resourcename = `/${props.resource}`;
        //let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
        let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() === "/candidates");
        propsObj.hasList = false;
        propsObj.hasShow = false;
        propsObj.hasEdit = false;
        propsObj.hasDelete = false;
        propsObj.hasCreate = false;
        propsObj.hasExport = false;
        if (myPermissions.length > 0) {
            let p = myPermissions[0];
            propsObj.hasList = (p.View == 1) ? true : false;
            propsObj.hasShow = (p.View == 1) ? true : false;
            propsObj.hasEdit = (p.Edit == 1) ? true : false;
            propsObj.hasDelete = (p.Delete == 1) ? true : false;
            propsObj.hasCreate = (p.Create == 1) ? true : false;
            propsObj.hasExport = (p.Export == 1) ? true : false;
        }
    }
    localStorage.setItem("candidateCustom", "/candidates?type=" + usertype);
    const savedDisplayAccountType = localStorage.getItem("displayCandidateType");
    if (!values.type && savedDisplayAccountType != undefined) {
        usertype = savedDisplayAccountType;
    }
    localStorage.setItem("displayCandidateType", usertype);
    var loggedInuserType = localStorage.getItem("type");
    var Ownrecords = localStorage.getItem("Ownrecords");
    var userId = localStorage.getItem("userId");
    var filter = {typeId:46};
    let hasCreate = false;
    if (usertype != 0) {
     //   filter = { empRoleTypeId: usertype };
        hasCreate = true;
    }
    let recruiterId;
        recruiterId = localStorage.getItem("recruiterId");
        if (recruiterId) {
            recruiterId = parseInt(recruiterId);
        }
        
    debugger
    if (Ownrecords == "true") {
        filter.recruiterId = recruiterId;
    } 
    const rowStyle = (record, index) => {

        if (record.isActive === false) {
            return { backgroundColor: '#FF7F7F' };
        }
        else {
            return { backgroundColor: 'inherit' };
        }
    }

    return (
        <List {...propsObj} title="Candidate Tracker" exporter={propsObj.hasExport} 
        bulkActionButtons={false} filter={filter} filters={<CandidateFilter />} 
        actions={<ListActions hasCreate={propsObj.hasCreate} />}>
            <Datagrid rowClick={'edit'} rowStyle={rowStyle} >
                {propsObj.hasEdit && <EditButton />}
                {propsObj.hasEdit === false && propsObj.hasShow === true && <EditButton label={"Show"} />}
                <TextField source="candidateCode" label="Candidate Code" className="employee-code" formClassName="employee-code" />
                <BooleanField source="isActive" label="Is Active" />
                <FullNameField label="Full Name" />
                <ReferenceField link={false} source="recruiterId" label="Recruiter" reference="recruiters"><TextField source="name" /></ReferenceField>
                {/* <TextField source="firstName"  label="First Name" />
                <TextField source="middleName" label="Middle Name"/>
                <TextField source="lastName" label="Last Name"/> */}
                <DateField source="doj" label="DOJ" locales="fr-FR" />
                <ReferenceField link={false} source="designationId" reference="designations"><TextField source="name" /></ReferenceField>
                <ReferenceField link={false} source="subjectId" label="Subject" reference="subjects"><TextField source="name" /></ReferenceField>
                
                            
                {/* <ReferenceField link={false} source="departmentId" reference="departments"><TextField source="name" /></ReferenceField> */}
                {/* <SelectField source="empRoleTypeId" label="Employment" choices={[
                    { id: 1, name: 'On-Roll' },
                    { id: 3, name: 'Freelancer' },
                    { id: 4, name: 'Contractual' },
                ]} /> */}
                <EmailField source="personnelEmail" label="Personal Email" />
                <TextField source="mobile" label="Mobile Number" />
                <TextField source="skypeId" label="Skype ID" reference="skypes"></TextField>
                <DateField source="entryDate" locales="fr-FR" />
                <ReferenceField link={false} source="status" reference="statuses"><TextField source="status" /></ReferenceField>
            </Datagrid>
        </List>
    )
};