import React from 'react';

import {
    List, Datagrid, TextField, DateField, NumberField,
    BooleanField, EditButton, ReferenceField,Filter,TextInput,ReferenceInput,AutocompleteInput,
    useListContext,CreateButton
}
    from 'react-admin';
const TestFilter = props => (
        <Filter variant="outlined" {...props}>
            
             <TextInput variant="outlined"  label="Requisition No" source="requisitionNo~like" alwaysOn />
            <ReferenceInput label="Project" variant="outlined"  source="projectId" reference="work-role-projects" alwaysOn>
                <AutocompleteInput optionText={choice => { return (choice?.name) ? choice.name : "All"; } } />
            </ReferenceInput>
            <TextInput variant="outlined"  label="Requisition Subject" source="notiSubject~like" />
            <ReferenceInput label="Technical Skill" variant="outlined"  source="keySkillId" reference="key-skills" alwaysOn>
                <AutocompleteInput optionText={choice => { return (choice?.name) ? choice.name : "All"; } } />
            </ReferenceInput>
            <ReferenceInput label="Experience" variant="outlined"  source="experienceId" reference="experiences" >
                <AutocompleteInput optionText={choice => { return (choice?.name) ? choice.name : "All"; } } />
            </ReferenceInput> 
            
        </Filter>
    );
export const NotificationList = props => {
    const RoleTypeId = localStorage.getItem("RoleTypeId"); 
    const { resource, basePath } = useListContext();
    const isAdmin = (RoleTypeId?.toLocaleLowerCase() == "1" ) ? true : false;
    let propsObj = { ...props };
    if (propsObj.permissions) {
      let resourcename = `/${props.resource}`;
      let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
      if (myPermissions.length > 0) {
        let p = myPermissions[0];
        propsObj.hasList = (p.View == 1) ? true : false;
        propsObj.hasShow = (p.View == 1) ? true : false;
        propsObj.hasEdit = (p.Edit == 1) ? true : false;
        propsObj.hasDelete = (p.Delete == 1) ? true : false;
        propsObj.hasCreate = (p.Create == 1) ? true : false;
        propsObj.hasExport = (p.Export == 1) ? true : false;
      }
    }

const CustomEmpty = () => (
        <div style={{ textAlign: 'center', margin: '20px' }}> 
            <h2 style={{ color: '#555', fontWeight: '500', fontSize: '1.5rem' }}>No Records Found</h2>
        {propsObj && propsObj.hasCreate && <CreateButton basePath={basePath} />}  

    </div>
);

    return (
        <List {...propsObj} title="Requisition List" exporter={propsObj.hasExport} bulkActionButtons={false} filters={<TestFilter />} filter={parseInt(localStorage.RoleTypeId)===1?null:{ 'createdBy': parseInt(localStorage.userId) } }
        empty={<CustomEmpty />}
        >
        <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}> 
        {propsObj.hasEdit && <EditButton />} 
            <TextField source="requisitionNo" label="Requisition No" />
             <DateField source="date" label="Date"/>  
            <ReferenceField  label="For Project" source="projectId" reference="work-role-projects" link={false}>
                    <TextField source="name" />
            </ReferenceField>
            <DateField  source="startDate" label="Project Start Date"/>
            <DateField  source="endDate" label="Project End Date"/>
            <DateField  source="lastRespDate" label="Last Receiving Date"/>
            <ReferenceField source="workRoleId" reference="work-roles" label="Business Unit"  link={false}>
                <TextField source="name" />
            </ReferenceField> 
            <ReferenceField source="keySkillId" reference="key-skills" label="Technical Skill"  link={false}>
                <TextField source="name" />
            </ReferenceField> 
            <ReferenceField source="experienceId" reference="experiences" label="Experience"  link={false}>
                <TextField source="name" />
            </ReferenceField> 
            <ReferenceField source="evaluatorId" reference="evaluators" label="Evaluator"  link={false}>
                <TextField source="name" />
            </ReferenceField> 
            <ReferenceField source="recruiterId" reference="recruiters" label="Recruiter"  link={false}>
                <TextField source="name" />
            </ReferenceField>  
            <TextField source="notiSubject" label="Requisition Subject" /> 
            <TextField source="totalResource" label="Total Resource Required" />
            <TextField source="totalSent" label="Total Requisition Sent" />
            <TextField source="totalAccepted" label="Total Requisition Accepted" />
            <TextField source="totalRejected" label="Total Requisition Rejected" />
            <TextField source="totalPending" label="Total Requisition Pending" />  
            {/* <ReferenceField source="createdBy" reference="employees" label="Created By"  link={false}>
                <TextField source="firstName" />
            </ReferenceField> */}
            <DateField  source="createdDate" label="Created Date"/> 
            
        </Datagrid>
    </List>
)};