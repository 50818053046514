import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";
import { Create } from 'react-admin';
export const CandidateKeySkilEdit = props => {
    const classes = useStyles({});
    const initialValues = {
        isActive: true 
    }
    const handleSubmit = (formdata) => { 
        
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
          userId = parseInt(userId);
        }

        let data={...formdata};
        let m = moment(new Date());
        if(data.createdBy==null)
            {
                data.createdBy=userId;
            }
            if(data.createdDate==null)
                {
                    data.createdDate= m.toISOString();
                }
 
        data. modifyDate=  m.toISOString()
        data. modifyBy=  userId
        apiClient.updateRecord("candidate-key-skills", data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props} id={props.record.id} resource="candidate-key-skills" redirect={false}>
           <SimpleForm
                onSubmit={handleSubmit}
                
                toolbar={<CreateToolbar onSave={handleSubmit} label="Save" onClose={props.onCancel} />}
                variant="outlined">
                <ReferenceInput perPage={500} label="Technical Skill" validate={required()} source="keySkillId" reference="key-skills"
                    fullWidth={true}sort={{ field: 'name', order: 'ASC' }} >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                
                <TextInput fullWidth={true} multiline source="remarks" label="Remarks" />
                
            </SimpleForm>
        </Edit>
    );
}
