import React, { useEffect, useCallback, useState } from 'react';
import RichTextInput from 'ra-input-rich-text';
import {
    useRefresh,
    required,
    useNotify,
    useRedirect,
    minLength,
    maxLength,
    minValue,
    ReferenceField,
    TextField,
    DateField,
    NumberField,
    ReferenceManyField,
    Datagrid,
    FormTab,
    AutocompleteInput,
    Edit, TabbedForm, TextInput, SelectInput, BooleanField,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, useQueryWithStore, FormDataConsumer
} from 'react-admin';
import { useStyles } from "../../formStyles";
import { makeStyles } from '@material-ui/core/styles';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import VSDeleteButton from "../../VSDeleteButton";
import { EmployeeList } from './EmployeeList'
import Button from '@material-ui/core/Button';
import { Drawer } from '@material-ui/core';
import { EmployeeFields } from './EmployeeFields';
import { DisplayDate } from '../../common/DisplayDate';
import StageCreate from './StageCreate';
import EditIcon from '@material-ui/icons/Edit';
import StageEdit from './StageEdit';
import CandidateCreate from './CandidateCreate';
import { Subjects } from './Subjects';
import EvaluatorCreate from './EvaluatorCreate';
import RecruiterCreate from './RecruiterCreate';
import SubjectCreate from './SubjectCreate';
import { ContactInfo } from './ContactInfo';
import queryString from 'query-string';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useLocation
} from "react-router-dom";



const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: "90%"
    },
}));

export const NotificationEdit = props => {
    const classes = useStyles({});
    
    const myclasses = myStyles({});
    const refresh = useRefresh();
    const [isAddResourceOpen, setIsAddResourceOpen] = useState(false);
    const [isAddStage, setIsAddStage] = useState(false);
    const [isEditStage, setIsEditStage] = useState(false);
    const [isAddSubject, setIsAddSubject] = useState(false);
    const [IsEditRecord, setIsEditRecord] = useState(null);
    const [cols, setCols] = useState([]);
    const [griddata, setData] = useState([]);
    const [isAddCandidate, setIsAddCandidate] = useState(false);
    const [isAddRecruiter, setIsAddRecruiter] = useState(false);
    const [isAddEvaluator, setIsAddEvaluator] = useState(false);
    const [isSubjectId, setSubjectId] = useState(false);

    
        

        let propsObj = { ...props };
        let location = useLocation();
    
        const values = queryString.parse(location.search);
        let usertype = (values.type) ? values.type : 0;
    
        if (propsObj.permissions) {
            let resourcename = `/${props.resource}`;
            let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
            //let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() === "/candidates");
            propsObj.hasList = false;
            propsObj.hasShow = false;
            propsObj.hasEdit = false;
            propsObj.hasDelete = false;
            propsObj.hasCreate = false;
            propsObj.hasExport = false;
            if (myPermissions.length > 0) {
                let p = myPermissions[0];
                propsObj.hasList = (p.View == 1) ? true : false;
                propsObj.hasShow = (p.View == 1) ? true : false;
                propsObj.hasEdit = (p.Edit == 1) ? true : false;
                propsObj.hasDelete = (p.Delete == 1) ? true : false;
                propsObj.hasCreate = (p.Create == 1) ? true : false;
                propsObj.hasExport = (p.Export == 1) ? true : false;
            }
        }
    



    const onCancel = () => {
        setIsAddResourceOpen(false);
        setIsAddStage(false);
        setIsEditStage(false);
        setIsAddCandidate(false);
        setIsAddRecruiter(false);
        setIsAddEvaluator(false);
        setIsAddSubject(false);



        refresh();

    }
    const onCloseForm = () => {
        setIsAddResourceOpen(false);
        setIsAddStage(false);
        setIsEditStage(false);
        setIsAddCandidate(false);
        setIsAddRecruiter(false);
        setIsAddEvaluator(false);
        setIsAddSubject(false);
        refresh();
        //loadNotificationDetail();
    }
    useEffect(() => {
        //loadNotificationDetail()
    }, []);
    const loadNotificationDetail = async () => {

        let formData = {
            notificationId: props.id,
        }

        apiClient.getNotificationdetail(formData).then((res) => {
            setData(res);
            if (res.length > 0) {
                let keys = Object.keys(res[0]);
                let data = [];
                for (let index = 0; index < keys.length; index++) {
                    if (keys[index] != "NotificationDetID") {
                        data.push(keys[index])
                    }
                }
                setCols(data);
            }

        }
        )
    }
    const CustomEdit = (props) => {
        let { record } = props;
        return <Button
            color="secondary" disableElevation
            size="medium"

            onClick={() => {
                props.onClick(record);
            }}
            startIcon={<EditIcon />}>Edit</Button>
    }

    const CustomCandidate = (props) => {
        const { record } = props;
        const { data, loading, error } = useQueryWithStore({
            type: 'getOne',
            resource: 'candidates',
            payload: { id: record.candidateId },
        });

        if (loading) {
            return <div>Loading....</div>;
        }

        if (error) {
            console.error("Error fetching data:", error);
            return <div>Error loading data</div>;
        }

        if (record.typeId === 46) {
            return <div>{data.firstName} {data.lastName}</div>; // Replace 'firstName' with the actual property you want to display
        } else if (record.typeId === 45) {
            return <div>{data.firstName} {data.lastName}</div>; // Replace 'firstName' with the actual property you want to display
        } else {
            return <div>No data available</div>;
        }
    }


    return (
        <React.Fragment>
            <Edit {...props} title="Requisition Edit" redirect={false} successMessage="Requisition Updated" undoable={false}>
                <TabbedForm redirect="list" variant="outlined" toolbar={<CreateToolbar showdelete={propsObj.hasDelete} {...props} />}   >
                    <FormTab value="DETAILS1" label="Details">

                        <TextField source="requisitionNo" label="Requisition No" fullWidth={true} disabled />
                        <DateInput source="date" label="Date" fullWidth={true} formClassName={classes.one_three_input} disabled />
                        <ReferenceInput  perPage={500}  validate={required()} label="For Project" source="projectId" reference="work-role-projects" fullWidth={true} formClassName={classes.two_three_input}
                        sort={{ field: 'name', order: 'ASC' }}
                        >
                             <AutocompleteInput
                                                optionText={choice =>
                                                    `${choice?.name} `
                                                }
                                            />
                                        
                        </ReferenceInput>
                        <TextInput source="notiSubject" label="Requisition Subject" fullWidth={true} formClassName={classes.last_three_input} validate={required()} />
                        <DateInput source="startDate" label="Project Start Date" fullWidth={true} formClassName={classes.one_three_input} validate={required()} />
                        <DateInput source="endDate" label="Project End Date" fullWidth={true} formClassName={classes.two_three_input} validate={required()} />
                        <DateInput source="lastRespDate" label="Last Receiving Date" fullWidth={true} formClassName={classes.last_three_input} validate={required()} />
                        {/* <FormDataConsumer >
                            {formDataProps => (
                                <Subjects {...formDataProps} 
                                />
                            )}
                        </FormDataConsumer>  */}
                        <ReferenceInput validate={required()} label="Business Unit" source="workRoleId" reference="work-roles" fullWidth={true} formClassName={classes.one_three_input}
                        sort={{ field: 'name', order: 'ASC' }}
                        >
                            <AutocompleteInput
                                optionText={choice =>
                                    `${choice?.name} `
                                }
                            />
                        </ReferenceInput>
                        <ReferenceInput validate={required()} label="Technical Skill" source="keySkillId" reference="key-skills" fullWidth={true} formClassName={classes.two_three_input}
                        sort={{ field: 'name', order: 'ASC' }}
                        >
                            <AutocompleteInput
                                optionText={choice =>
                                    `${choice?.name} `
                                }
                            />
                        </ReferenceInput>
                        <ReferenceInput validate={required()} label="Experience" source="experienceId" reference="experiences" fullWidth={true} formClassName={classes.last_three_input}
                        sort={{ field: 'name', order: 'ASC' }}
                        >
                            <AutocompleteInput
                                optionText={choice =>
                                    `${choice?.name} `
                                }
                            />
                        </ReferenceInput>
                        <TextInput source="profile" label="Profile"  fullWidth={true} validate={required()}/>
                        <RichTextInput source="notiDetails" label="Requisition Detail" />

                        <NumberInput source="totalResource" label="No Of Resources" fullWidth={true} formClassName={classes.one_three_input} validate={required()} />
                        <ReferenceInput perPage={25}  filter={{ typeId: 1}} sort={{ field: 'firstName', order: 'ASC' }} filterToQuery={searchText => ({ 'firstName~like': searchText })} validate={[required()]} label="HR Lead" source="teamLeadId" reference="users" fullWidth={true} formClassName={classes.two_three_input}>
                            <AutocompleteInput
                                optionText={choice =>
                                   `${choice?.firstName} ${choice?.lastName} `
                                }
                            />
                        </ReferenceInput>
                        
                        <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })} validate={[required()]} label="Work Shift" source="shiftId" reference="shifts" fullWidth={true} formClassName={classes.last_three_input}>
                            <AutocompleteInput
                                optionText={choice =>
                                    //`${choice.name}`
                                    choice && choice?.name ?choice?.name:''
                                }
                            />
                        </ReferenceInput>
                        <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })} validate={[required()]} label="Work Location" source="workLocationId" reference="work-locations" fullWidth={true} formClassName={classes.one_three_input}>
                            <AutocompleteInput
                                optionText={choice =>
                                    `${choice?.name}`
                                }
                            />
                        </ReferenceInput>
                        <NumberInput source="securePer" label="Min Secure %" fullWidth={true} formClassName={classes.two_three_input} validate={required()} />
                        <BooleanInput source="isActive" label="Is Active" fullWidth={true} formClassName={classes.last_three_input} validate={required()} />

                        <FormDataConsumer >
                            {formDataProps => (
                                setSubjectId((formDataProps.formData.subjectId))

                            )}
                        </FormDataConsumer>
                        <BooleanInput initialValue={true} source="isActive" label="Is Active"  fullwidth={true} />
                        <RichTextInput source="projectContactInformation" label="Contact Information" />
                        {/* <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <ContactInfo {...formDataProps} />
              )}
            </FormDataConsumer> */}
                    </FormTab>
                    <FormTab value="Other" label=" Subject and Rate setup">
                        <Button variant="contained" onClick={() => setIsAddSubject(true)}
                            color="secondary">
                            Add Subject Rate

                        </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="notification-subjects"
                            target="notificationId">

                            <Datagrid >

                                <VSDeleteButton {...props} />
                                {/* <CustomEdit onClick={(record) => {
                                    setIsEditRecord(record); setIsAddSubject(true);
                                }} label="Edit" /> */}
                                <ReferenceField source="subjectId" reference="subjects" label="Subject" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>

                                <ReferenceField source="subjectPaymentId" reference="subject-payments" label="Payment Code" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField source="subjectPaymentId" reference="subject-payments" label="Min Rate" link={false}>
                                    <NumberField source="minRate" />
                                </ReferenceField>
                                <ReferenceField source="subjectPaymentId" reference="subject-payments" label="Max Rate" link={false}>
                                    <NumberField source="maxRate" />
                                </ReferenceField>
                                {/* <ReferenceField source="testId" reference="tests" label="Test" link={false}>
                                    <NumberField  source="name" />
                                </ReferenceField> */}


                                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                    <TextField source="userName" />
                                </ReferenceField>

                                <DisplayDate source="createdDate" label="Created Date" locales="fr-FR" />
                                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                    <TextField source="userName" />
                                </ReferenceField>
                                <DisplayDate source="modifyDate" label="Updated Date" locales="fr-FR" />

                            </Datagrid>

                        </ReferenceManyField>
                    </FormTab>
                    <FormTab value="Other" label="Selection Process">
                        <Button variant="contained" onClick={() => setIsAddStage(true)}
                            color="secondary">
                            Add Process

                        </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="notification-stages"
                            target="notificationId">

                            <Datagrid >

                                <VSDeleteButton {...props} />
                                <CustomEdit onClick={(record) => {
                                    setIsEditRecord(record); setIsEditStage(true);
                                }} label="Edit" />


                                <ReferenceField source="selectionStageId" reference="selection-stages" label="Selection Stage" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField source="sequenceId" reference="statuses" label="Selection Sequence" link={false}>
                                    <TextField source="status" />
                                </ReferenceField>
                                <ReferenceField source="statusId" reference="statuses" label="Status" link={false}>
                                    <TextField source="status" />
                                </ReferenceField>
                                <BooleanField source="isMailTrigger" label="Mail Trigger" />
                                <BooleanField source="isTestLink" label="Share Test Link" /> 
                                <BooleanField source="isApplicableForCandidate" label="Is Applicable For Candidate" /> 
                                <BooleanField source="isApplicableForEmployee" label="Is Applicable For Freelancer" /> 
                                <BooleanField source="isAssignedToEvaluator" label="Assign Evaluator" /> 
                                
            
                                {/* <TextField source="remarks" label="Remarks" />
                                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                    <TextField source="userName" />
                                </ReferenceField>

                                <DisplayDate source="createdDate" label="Created Date" locales="fr-FR" />
                                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                    <TextField source="userName" />
                                </ReferenceField>
                                <DisplayDate source="modifyDate" label="Updated Date" locales="fr-FR" /> */}

                            </Datagrid>

                        </ReferenceManyField>
                    </FormTab>

                    <FormTab value="Other" label="Recruiters">
                        <Button variant="contained" onClick={() => setIsAddRecruiter(true)}
                            color="secondary">
                            Add Recruiter
                        </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="notification-recruters"
                            target="notificationId">
                            <Datagrid >
                                <VSDeleteButton {...props} />
                                <ReferenceField source="recruiterId" reference="recruiters" label="Recruiter" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <TextField source="remarks" label="Remarks" />
                                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                    <TextField source="userName" />
                                </ReferenceField>
                                <DisplayDate source="createdDate" label="Created Date" locales="fr-FR" />
                                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                    <TextField source="userName" />
                                </ReferenceField>
                                <DisplayDate source="modifyDate" label="Updated Date" locales="fr-FR" />

                            </Datagrid>

                        </ReferenceManyField>
                    </FormTab>

                    <FormTab value="Other" label="Evaluator">
                        <Button variant="contained" onClick={() => setIsAddEvaluator(true)}
                            color="secondary">
                            Add Evaluator

                        </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="notification-evaluators"
                            target="notificationId">
                            <Datagrid >
                                <VSDeleteButton {...props} />
                                <ReferenceField source="evaluatorId" reference="evaluators" label="Evaluator" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>

                                <TextField source="remarks" label="Remarks" />
                                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                    <TextField source="userName" />
                                </ReferenceField>
                                <DisplayDate source="createdDate" label="Created Date" locales="fr-FR" />
                                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                    <TextField source="userName" />
                                </ReferenceField>
                                <DisplayDate source="modifyDate" label="Updated Date" locales="fr-FR" />

                            </Datagrid>

                        </ReferenceManyField>
                    </FormTab>

                    {/* <FormTab value="Other" label="Requisition Candidates">
                        <Button variant="contained" onClick={() => setIsAddCandidate(true)}
                            color="secondary">
                            Add Candidate

                        </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="notification-dets"
                            target="notificationId"> 
                            <Datagrid > 
                                <VSDeleteButton {...props} /> 
                                <ReferenceField source="typeId" reference="statuses" label="Candidate Type" link={false}>
                                    <TextField source="status" />
                                </ReferenceField> 
                                <CustomCandidate source="candidateId" label="Candidate" /> 
                                <ReferenceField source="statusId" reference="statuses" label="Status" link={false}>
                                    <TextField source="status" />
                                </ReferenceField>
                                <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                                        <TextField source="firstName" />
                                </ReferenceField>
                                
                                <DisplayDate source="createdDate" label="Created Date" locales="fr-FR" />
                                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                    <TextField source="firstName" />
                                </ReferenceField>
                                <DisplayDate source="modifyDate" label="Updated Date" locales="fr-FR" /> 
                            </Datagrid>

                        </ReferenceManyField>
                    </FormTab>
 */}

                </TabbedForm>

            </Edit>
            <Drawer anchor="right" onClose={onCancel} classes={{
                paper: myclasses.drawerPaper,
            }}
                open={isAddResourceOpen || isAddStage || isEditStage || isAddCandidate || isAddEvaluator || isAddRecruiter || isAddSubject}>
                {isAddCandidate &&
                    <EmployeeFields

                        onClose={onCloseForm}
                        onCancel={onCancel}
                        notificationId={props.id}
                        subjectId={isSubjectId}
                        {...props} />}
                {isAddStage &&
                    <StageCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        notificationId={props.id}
                        subjectId={isSubjectId}
                        {...props} />}
                {isAddEvaluator &&
                    <EvaluatorCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        notificationId={props.id}
                        {...props} />}
                {isAddSubject &&
                    <SubjectCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        notificationId={props.id}
                        {...props} />}
                {isAddRecruiter &&
                    <RecruiterCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        notificationId={props.id}
                        subjectId={isSubjectId}
                        {...props} />}
                {isEditStage && <StageEdit
                    onClose={onCloseForm}
                    onCancel={onCancel}
                    record={IsEditRecord}
                    subjectId={isSubjectId}
                    {...props} />}

                {/* {isAddCandidate &&
                    <CandidateCreate 
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        notificationId={props.id}
                        {...props} />}  */}


            </Drawer>



        </React.Fragment>);
}
