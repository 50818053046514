import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";
import { Button } from '@material-ui/core';

export const Subjects = ({ formData, ...rest }) => {
    const form = useForm();
    
    const classes = useStyles({});
    return (<div style={{ width: '100%',display:'flex',flexDirection:'row' }} >
        <div style={{ width: '30%',marginRight:'1%' }}>
        <ReferenceInput validate={required()} variant="outlined" label="Subject" source="subjectId" reference="subjects" fullWidth={true} formClassName={classes.one_three_input}
        perPage={2500} sort={{ field: 'name', order: 'ASC' }}>
            <AutocompleteInput optionText={choice => `${choice?.name} ` } /> 
        </ReferenceInput>   
        </div>
        <div style={{ width: '30%' }}>
        {!formData.subjectId && <ReferenceInput variant="outlined" perPage={500} sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ 'name~like': searchText })} validate={[required()]}
            label="Subject Payment " source="subjectPaymentId" reference="subject-payments"  
             fullWidth={true} 
                         >
                                 {/* <SelectInput optionText="name" /> */}
                                 <AutocompleteInput
                                 optionText={choice =>
                                     `${choice?.name} ( ${choice?.minRate} - ${choice?.maxRate} )`
                                 }
            />
        </ReferenceInput>}    
        {formData.subjectId && <ReferenceInput variant="outlined" perPage={500} sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ 'name~like': searchText })} validate={[required()]}
            label="Payment Code " source="subjectPaymentId" reference="subject-payments" 
            
             fullWidth={true} >
                                <AutocompleteInput
                                 optionText={choice =>
                                     `${choice?.name} ( ${choice?.minRate} - ${choice?.maxRate} )`
                                 }
            />
        </ReferenceInput>}
        
        </div>
         
    </div>
    )
}
