import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,FormDataConsumer,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import { AddressFields } from "../../AddressFields";
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";
import { Create } from 'react-admin';

export const CandidateProjectCreate = props => {
    const classes = useStyles({});
    const initialValues = {
         
    }
    const handleSubmit = (formdata) => {
        let startDate = (formdata.startDate) ? moment(formdata.startDate).format() : null;
        let endDate = (formdata.endDate) ? moment(formdata.endDate).format() : null;
        let m = moment(new Date());
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
            userId = parseInt(userId);
        }
        let data = {
            candidateId: parseInt(props.employeeId),
            workRoleProjectId:  parseInt(formdata.workRoleProjectId), 
            workRoleId:  parseInt(formdata.workRoleId), 
            subjectId:  parseInt(formdata.subjectId), 
            linkId: 0, 
            startDate:startDate,
            endDate:endDate,
            
            createdBy: userId,
            modifyBy: 0,
            createdDate: m.toISOString(),
            modifyDate: m.toISOString(),
        }   
        if (data.endDate ==null)
            delete data.endDate

    
        apiClient.addEntiyRecord('candidate-project-mappings',data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Create {...props}  resource="candidate-project-mappings" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<CreateToolbar onSave={handleSubmit} label="Save" onClose={props.onCancel} />}
                variant="outlined">
                <ReferenceInput perPage={500} label="For Project" validate={required()} source="workRoleProjectId" reference="work-role-projects"
                    fullWidth={true}   sort={{ field: 'name', order: 'ASC' }} >
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <ReferenceInput perPage={500} label="Business Unit" validate={required()} source="workRoleId" reference="work-roles"
                    fullWidth={true}   sort={{ field: 'name', order: 'ASC' }} >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                
                <ReferenceInput perPage={500} label="Subject" validate={required()} 
                source="subjectId" reference="subjects" sort={{ field: 'name', order: 'ASC' }} 
                    fullWidth={true}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                
              
                <DateInput source="startDate"  validate={required()} fullWidth={true} label="Start Date" formClassName={classes.first_inline_input} />
                <DateInput source="endDate"   fullWidth={true} label="End Date" formClassName={classes.last_inline_input}/>
                
            </SimpleForm>
        </Create>
    );
}
