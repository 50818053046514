import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";
import { Create } from 'react-admin';
export const CandidateKeySkillCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        isActive: true 
    }
    const handleSubmit = (formdata) => { 
        
        
        let m = moment(new Date());
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
            userId = parseInt(userId);
        }
        let data = {
            candidateId: parseInt(props.employeeId),
            keySkillId:  parseInt(formdata.keySkillId), 
            remarks: formdata.remarks? formdata.remarks:"",
            isActive: true,
            createdBy: userId,
            modifyBy: 0,
            createdDate: m.toISOString(),
            modifyDate: m.toISOString(),
        }   
        apiClient.addEntiyRecord('candidate-key-skills',data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Create {...props}>
           <SimpleForm
                onSubmit={handleSubmit}
                
                toolbar={<CreateToolbar onSave={handleSubmit} label="Save" onClose={props.onCancel} />}
                variant="outlined">
                <ReferenceInput perPage={500} label="Technical Skill" validate={required()} source="keySkillId" reference="key-skills"
                    fullWidth={true}sort={{ field: 'name', order: 'ASC' }} >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                
                <TextInput fullWidth={true} multiline source="remarks" label="Remarks" />
                
            </SimpleForm>
        </Create>
    );
}
