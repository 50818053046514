import React from 'react';
import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices, Create, SimpleForm, TextInput, DateInput,
    ImageInput, ImageField, ReferenceInput, SelectInput, FormDataConsumer,
    BooleanInput, NumberInput,AutocompleteInput
} from 'react-admin';
import { useStyles } from "../../formStyles";
import { AddressFields } from "../../AddressFields";

export const CandidateCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        isActive: true,
        roEmail: "",
        bloodgroupId:0,
        photoUrl:'',
        candidateCode:'Auto Generate',
        personnelEmail:'',
        status:54,
        designationId:226,
        typeId:46,
        empCode:"",
    }

    return (
        <Create {...props} redirect="list">
            <SimpleForm initialValues={initialValues} variant="outlined">
                <TextInput label="Candidate Code" source="candidateCode" validate={required()} fullWidth={true} formClassName={classes.first_inline_input}  disabled/> 
                <ReferenceInput label="Initials" validate={required()} source="initialsId" reference="salutations"
                    fullWidth={true} formClassName={classes.last_inline_input} sort={{ field: 'name', order: 'ASC' }}  >
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <TextInput source="firstName" label="First Name" validate={required()} fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput source="middleName" label="Middle Name" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput source="lastName" label="Last Name" validate={required()} fullWidth={true} formClassName={classes.last_three_input} />

                <TextInput source="personnelEmail" label="Personal Email" validate={[required(), email()]} fullWidth={true} formClassName={classes.first_inline_input} />
                <TextInput source="alternateEmail" label="Alternate Email"  validate={[ email()]} fullWidth={true} formClassName={classes.last_inline_input} />
                {/* <TextInput source="personnelEmail" label="Alternate Email 1" validate={[ email()]}  fullWidth={true} formClassName={classes.last_three_input} /> */}

                <TextInput source="mobile" label="Contact Number" validate={required()} fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput source="otherMobile" label="Alternate Contact No" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput source="homeMobile" label="Home Contact No" fullWidth={true} formClassName={classes.last_three_input} />
                <SelectInput source="gender" label="Gender" fullWidth={true} formClassName={classes.one_three_input}
                    choices={[
                        { id: 'male', name: 'Male' },
                        { id: 'female', name: 'Female' },
                        { id: 'other', name: 'Other' },
                    ]} />
                <TextInput source="skypeId"  label="Skype ID"fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput source="linkedInId" label="LinkedIn" fullWidth={true} formClassName={classes.last_three_input} />

                <TextInput source="emergencyContactName" label="Emergency Contact Name" fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput source="emergencyContactRelation" label="Emergency Contact Relation" fullWidth={true} formClassName={classes.last_three_input} />
                
                <TextInput source="emergencyMobile" label="Emergency Mobile No" fullWidth={true} formClassName={classes.last_three_input} />
                {/* <TextInput source="emergencyOtherMobile" label="Emergency Other Mobile No" fullWidth={true} formClassName={classes.last_three_input} /> */}

               
                <h4>Present Address</h4>
                <TextInput source="presentAddress1"  label="Address Line 1" fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput source="presentAddress2" label="Address Line 2" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput label="PIN Code" source="presentPinCode" fullWidth={true} formClassName={classes.last_three_input} /> 
                <FormDataConsumer>
                    {formDataProps => (
                        <AddressFields {...formDataProps}  showAsAbove={true}
                            countryField="presentCountryId" stateField="presentStateId" cityField="presentCityId"
                            countryLabel="Country" stateLabel="State" cityLabel="City"
                        />
                    )}
                </FormDataConsumer>


                {/* <ReferenceInput validate={required()} label="Present Country" source="presentCountryId" reference="countries"
                    fullWidth={true} formClassName={classes.one_three_input}>
                    <SelectInput optionText="countryName" />
                </ReferenceInput>

                <ReferenceInput validate={required()} label="Present State" source="presentStateId" reference="states"
                    fullWidth={true} formClassName={classes.two_three_input}>
                    <SelectInput optionText="stateName" />
                </ReferenceInput>
                <ReferenceInput validate={required()} label="Present City" source="presentCityId" reference="cities"
                    fullWidth={true} formClassName={classes.last_three_input}>
                    <SelectInput optionText="cityName" />
                </ReferenceInput> */}


                <h4>Permanent Address</h4>
                <TextInput  source="permanentAddress1" label="Address Line 1" fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput source="permanentAddress2" label="Address Line 2" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput label="PIN Code"  source="permanentPinCode" fullWidth={true} formClassName={classes.last_three_input} />

                <FormDataConsumer>
                    {formDataProps => (
                        <AddressFields {...formDataProps} showAsAbove={false}
                            countryField="permanentCountryId" stateField="permanentStateId" cityField="permanentCityId"
                            countryLabel="Country" stateLabel="State" cityLabel="City"
                        />
                    )}
                </FormDataConsumer>
<ReferenceInput label="Nationality" source="nationalityId" reference="nationalities"
                            fullWidth={true} sort={{ field: 'name', order: 'ASC' }} >
                            <SelectInput optionText="name" initialValue={0} />
                        </ReferenceInput>

                {/* <ReferenceInput validate={required()} label="Permanent Country" source="permanentCountryId" reference="countries"
                    fullWidth={true} formClassName={classes.last_three_input}>
                    <SelectInput optionText="countryName" />
                </ReferenceInput>

                <ReferenceInput validate={required()} label="Permanent State" source="permanentStateId" reference="states"
                    fullWidth={true} formClassName={classes.one_three_input}>
                    <SelectInput optionText="stateName" />
                </ReferenceInput>
                <ReferenceInput validate={required()} label="Permanent City" source="permanentCityId" reference="cities"
                    fullWidth={true} formClassName={classes.two_three_input}>
                    <SelectInput optionText="cityName" />
                </ReferenceInput> */}





                {/* <ReferenceInput validate={required()} label="Work Role Type" source="roleTypeId" reference="role-types"
                    fullWidth={true} formClassName={classes.one_three_input}>
                    <SelectInput optionText="roleName" />
                </ReferenceInput> */}
                {/* <ReferenceInput validate={required()} label="Leave Category" source="leaveCategoryId" reference="leave-categories"
                    fullWidth={true} formClassName={classes.two_three_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput validate={required()} label="Shift" source="shiftId" reference="shifts"
                    fullWidth={true} formClassName={classes.last_three_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput> */}


                <DateInput label="Date Of Birth" source="dob" fullWidth={true} formClassName={classes.one_three_input} />
                {/* <DateInput validate={required()} source="doj" fullWidth={true} formClassName={classes.two_three_input} />
                <DateInput source="dol" fullWidth={true} formClassName={classes.last_three_input} /> */}

                {/* <ReferenceInput  validate={required()} label="Work Role Type" source="empRoleTypeId" reference="work-roles"
                    fullWidth={true} formClassName={classes.one_three_input}>
                    <SelectInput optionText="roleName" />
                </ReferenceInput> */}
                {/* <TextInput source="roEmail" fullWidth={true}    validate={[ email()]} formClassName={classes.two_three_input} /> */}
                {/* <ReferenceInput validate={required()} label="Department" source="departmentId" reference="departments"
                    fullWidth={true} formClassName={classes.first_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput> */}
                
                <ReferenceInput perPage={500} validate={required()} label="Role Applied For" source="designationId" reference="designations"  sort={{ field: 'name', order: 'ASC' }} 
                    fullWidth={true} formClassName={classes.last_three_input}filterToQuery={searchText => ({ 'name~like': searchText })}  
                    >
                    <AutocompleteInput
                            optionText={record =>
                                `${record?.name}`
                            }
                        />
                </ReferenceInput>

                {/* <ReferenceInput validate={required()} label="Vertical" source="workroleId" reference="work-roles"
                    fullWidth={true} formClassName={classes.first_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput validate={required()} label="Projects" source="workroleprojectId" reference="work-role-projects"
                    fullWidth={true} formClassName={classes.last_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput> */}


                <ReferenceInput validate={required()} label="Recruitment Source" source="recruitmentSourceId" reference="recruit-sources"

                    fullWidth={true} formClassName={classes.first_inline_input}filterToQuery={searchText => ({ 'name~like': searchText })}  
                    >
                    <AutocompleteInput
                            optionText={record =>
                                `${record?.name}`
                            }
                        />
                </ReferenceInput>
                <ReferenceInput   label="Blood Group" source="bloodgroupId" reference="blood-groups"  sort={{ field: 'name', order: 'ASC' }} 
                    fullWidth={true} formClassName={classes.last_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <TextInput source="panCardNo" label="PAN No" fullWidth={true} formClassName={classes.first_inline_input} />
                <TextInput source="adharCardNo" label="Aadhaar No" fullWidth={true} formClassName={classes.last_inline_input} />

                <ReferenceInput validate={required()} label="Work Location" source="workLocationId" reference="work-locations"  sort={{ field: 'name', order: 'ASC' }} 
                
                    fullWidth={true} formClassName={classes.first_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput validate={required()} label="Week off" source="weekOffId" reference="weekoffs"  sort={{ field: 'name', order: 'ASC' }} 
                    fullWidth={true} formClassName={classes.last_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                {/* <TextInput validate={required()} source="userName" fullWidth={true} formClassName={classes.first_inline_input} />
                <TextInput validate={required()} source="password" fullWidth={true} formClassName={classes.last_inline_input} /> */}

                {/* <TextInput source="photoUrl" fullWidth={true} /> */}
                <TextInput source="remarks" multiline fullWidth={true} />

                <ReferenceInput label="Status" source="status" reference="statuses" filter={{ statusGroup: 'ER' }} disabled
                    fullWidth={true} formClassName={classes.first_inline_input}>
                    <SelectInput optionText="status" />
                </ReferenceInput>
                <ReferenceInput perPage={500} label="Subject" source="subjectId" reference="subjects"  sort={{ field: 'name', order: 'ASC' } } 
                    fullWidth={true} formClassName={classes.last_inline_input} filterToQuery={searchText => ({ 'name~like': searchText })}  
                    >
                    <AutocompleteInput
                            optionText={record =>
                                `${record?.name}`
                            }
                        />
                </ReferenceInput>

                <ReferenceInput label="Experience" source="experienceId" reference="experiences"  sort={{ field: 'name', order: 'ASC' }}
                
                filterToQuery={searchText => ({ 'name~like': searchText })}  
                    fullWidth={true} formClassName={classes.first_inline_input}>
                    <AutocompleteInput
                            optionText={record =>
                                `${record?.name}`
                            }
                        />

                </ReferenceInput>

                {/* <ReferenceInput label="Experience" source="recruiterId" reference="experiences"  sort={{ field: 'name', order: 'ASC' }} 
                    fullWidth={true} formClassName={classes.first_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput> */}

                

                <ReferenceInput perPage={500} sort={{ field: 'name', order: 'ASC' }}variant="outlined" validate={[required()]} label="Recruiter" source="recruiterId"
                filterToQuery={searchText => ({ 'name~like': searchText })} 
                
                 reference="recruiters" fullWidth={true} >
                        <AutocompleteInput
                            optionText={record =>
                                `${record?.name}`
                            }
                        />
                    </ReferenceInput>
                


                
               
            </SimpleForm>
        </Create>
    );
}
